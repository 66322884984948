import React, { useState } from 'react';
import './App.css';

const App: React.FC = () => {
  const [theme, setTheme] = useState('dark');

  const toggleTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark')
  }

  return (
    <div className="app" component-theme={ theme }>
      <button className="app-header" onClick={ toggleTheme }>
        Snacc
      </button>
    </div>
  );
};

export default App;
